import React, { PropsWithChildren, useEffect, useState } from 'react';
import { WrapRootElementBrowserArgs } from 'gatsby';
import { Helmet } from 'react-helmet';
import {
  atom,
  MutableSnapshot,
  RecoilRoot,
  selector,
  useRecoilState
} from 'recoil';
import RecoilNexus from 'recoil-nexus'
import { theme, ThemeProvider } from '@dls/web';
import QueryString from 'query-string';
import { useMedia } from 'use-media';
import '@dls/web/assets/fonts.css';
import { createGlobalStyle } from 'styled-components';
import merge from 'lodash/merge';
import clone from 'lodash/cloneDeep';
import FingerprintJS from '@wec-core/fingerprintjs';
import './index.css';
import media, { breakpoints } from './helpers/media';
import Api from './helpers/Api';
import Spinner from './components/Spinner';
import { AemConfigResponse, FlowConfig } from './helpers/RequestResponse';
import { verifyOrSetAgentCookie } from './helpers/utils';

interface GlobalStyleProps {
  background: string;
}

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  ${(p) => media.sm`
    html {
      background-color: grey;
      background-image: url('${p.background}');
      background-size: cover;
      background-position: center;
      overflow: hidden;
    }
    ${media.sm`
      div[class*='ModalContainer'] > div {
        width: 450px;
        padding: 0px;
        > div > div {
          padding: 0px;
          flex-basis: 100%;
          max-width: 100%;
        }
      }
    `}
    ${media.md`
      div[class*='ModalContainer'] > div {
        width: 460px;
        padding: 0px;
        > div > div {
          padding: 0px;
          flex-basis: 100%;
          max-width: 100%;
        }
      }
    `}
  `}
`;

export function RootWrapper(props: WrapRootElementBrowserArgs) {
  return (
    <RecoilRoot>
      <RecoilNexus/>
      <RootWrapperThemes>{props.element}</RootWrapperThemes>
    </RecoilRoot>
  );
}

const defaultAemConfig: AemConfigResponse = {
  background: '/sso/background.png',
  mode: 'light',
  onepassFlows: []
};

export const aemConfigState = atom<AemConfigResponse>({
  key: 'aemConfig',
  default: defaultAemConfig
});

export const flowConfigState = selector<FlowConfig>({
  key: 'flowConfig',
  get: ({ get }) => {
    let search = typeof window !== 'undefined' ? window.location.search : '';
    const qs = QueryString.parse(search);
    const flows = get(aemConfigState).onepassFlows || [];
    const flowId = qs.flowId || (qs.state && qs.state.split('--')[2]);
    return flowId ? flows.find((flow) => flow.flowId === flowId) : flows[0];
  }
});

export function RootWrapperThemes(props: React.PropsWithChildren<{}>) {
  const [config, setConfig] = useRecoilState(aemConfigState);
  const [loading, setLoading] = useState(true);

  const isWide = useMedia({ minWidth: breakpoints.sm });

  let modifiedTheme = {};
  if (isWide && config.mode === 'dark') {
    modifiedTheme = merge({}, theme, {
      main: { colours: { tertiary: { base: '#fff' } } }
    });
  } else {
    modifiedTheme = clone(theme);
  }

  useEffect(() => {
    const qs = QueryString.parse(window.location.search);
    const { onepassFlows } = config;
    if(onepassFlows
       && onepassFlows.length && !onepassFlows.find((flow) => flow.flowId === qs.flowId)
       && (window.location.pathname === '/' ||  window.location.pathname === '/sso/' || window.location.pathname.includes('/login'))) {
      window.location.href = `/login/?flowId=${onepassFlows[0].flowId}`;
    }
  }, [config]);

  useEffect(() => {
    if(typeof window !== undefined) {
      window.recaptchaOptions = {
        useRecaptchaNet: true
      }
    }
    const initConfig = async () => {
      const qs = QueryString.parse(window.location.search);
      const isUDF = (qs.flowId == '9762d9f3-55bf-4d60-8971-c739f7509da3');
      let payload = {cookies: {'max-age': '2592000'}};
      if(isUDF) {
        payload = { ...payload, excludeSources: ['privateIp']}
      }
      const fp = await FingerprintJS.load(payload)
      let { components, visitorId } = await fp.get();
      console.log(components);
      /*
          Intermittent issue with agentId, hence sharing the same
          agentId setinCookie in the browser
      */
      if (qs.flowId == 'f0e01c37-233f-4457-90d9-f94daeadd517') {
        visitorId = verifyOrSetAgentCookie(visitorId);
      }
      Api.aemConfig().then((res) => {
        const mappedData = res.data.onepassFlows.map((e) => ({ ...e, agentId: visitorId }));
        setConfig({...res.data, onepassFlows: mappedData});
        setLoading(false);
      });
    };
    initConfig();
  }, []);

  return (
    <>
      <Helmet>
        <title>Welcome to Singtel OnePass</title>
      </Helmet>
      <ThemeProvider theme={modifiedTheme}>
        <>
          <GlobalStyle background={config.background} />
          {loading ? <Spinner/> : props.children}
        </>
      </ThemeProvider>
    </>
  );
}

type Props = PropsWithChildren<{
  initializeState?: (mutableSnapshot: MutableSnapshot) => void;
}>;

export function TestRootWrapper(props: Props) {
  return (
    <RecoilRoot initializeState={props.initializeState}>
      <RecoilNexus/>
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyle background={defaultAemConfig.background} />
          {props.children}
        </>
      </ThemeProvider>
    </RecoilRoot>
  );
}
