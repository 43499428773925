import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile';
import { getCookie, setCookie } from '@lux/helpers';

export const isValidEmail = (email: string) => {
  const exp = /^([a-z0-9_\-.+*']{1,100})@([a-z0-9\-.]{1,})\.([a-z0-9]{2,})$/i;
  return exp.test(email);
}

export const hasAtSign = (value: string) => value.includes('@');

export const hasPlusPrefix = (value: string) => value.substring(0, 1) === '+';

export const isPlausibleDomain = (value: string) =>
  /(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g.test(
    value
  );

export const canInferCountryCode = (value: string) => {
  const parsedPhoneNumber = parsePhoneNumberFromString(value);
  return !!(parsedPhoneNumber && parsedPhoneNumber.country);
};

export const haveTwoDigitsWithoutPlus = (value: string) => {
  const hasAtLast2Digits = (value.length >= 8 || value.length >= 10) && !isNaN(parseInt(value));
  return !isValidEmail(value) && !hasPlusPrefix(value) && hasAtLast2Digits;
};

export const formatPhoneNumber = (numberWithPlusPrefix: string) => {
  const parsed = parsePhoneNumberFromString(numberWithPlusPrefix);
  if (parsed) {
    const { countryCallingCode, nationalNumber } = parsed;
    let phoneNumber = nationalNumber;
    if (countryCallingCode === '65' && nationalNumber.length >= 5) {
      phoneNumber =
        nationalNumber.substr(0, 4) + ' ' + nationalNumber.substr(4);
    }
    return `+${countryCallingCode} ${phoneNumber}`;
  }
  return numberWithPlusPrefix;
};

export const formatUsername = (username: string) => {
  if (haveTwoDigitsWithoutPlus(username)) {
    return username.substr(0, 2) === '65'
      ? formatPhoneNumber(`+${username}`)
      : formatPhoneNumber(`+65 ${username}`);
  }
  return formatPhoneNumber(username);
};

export const ErrorMessages = {
  INVALID_LOGIN_ID: 'Enter a valid login ID',
  INVALID_SINGNET_ID: 'Please enter a valid Singnet ID',
  RESTRICTED_SINGNET_ID: 'BLOCK_SINGNET_IDS',
  MISSING_EMAIL_DOMAIN: 'Enter a valid email format e.g. joe@abc.com', // e.g. jehdw@
  MISSING_EMAIL_USER: 'Enter a valid email', // e.g. jehdw.com
  INVALID_PHONE: 'Enter a valid mobile number'
};

export const handleUsernameValidation = (portal: string ) => {
  const validationList = {
    'udf' : validateUsernameforGE,
    'myportal': validateUsernamePortal18
  };
  return validationList[portal] || validateUsername;
};

export function validateUsername(username: string): string | true {
  if (hasPlusPrefix(username)) {
    return !canInferCountryCode(username) ? ErrorMessages.INVALID_PHONE : true; // mobile number
  } else if (hasAtSign(username)) {
    return !isValidEmail(username) ? ErrorMessages.MISSING_EMAIL_USER : true; // email address
  } else if (isPlausibleDomain(username)) {
    return ErrorMessages.MISSING_EMAIL_DOMAIN;
  } else {
    return ErrorMessages.INVALID_LOGIN_ID; // none of them
  }
}

export function validateUsernameForSingnet(username: string): string | true {
  if (hasPlusPrefix(username)) {
    return !canInferCountryCode(username) ? ErrorMessages.INVALID_PHONE : true; // mobile number
  } else if (hasAtSign(username) && username.includes('@singnet.com')) {
    return ErrorMessages.RESTRICTED_SINGNET_ID; // signet user
  } else if (hasAtSign(username)) {
    return !isValidEmail(username) ? ErrorMessages.MISSING_EMAIL_USER : true; // email address
  } else if (isPlausibleDomain(username)) {
    return ErrorMessages.MISSING_EMAIL_DOMAIN;
  } else {
    return ErrorMessages.INVALID_LOGIN_ID; // none of them
  }
}

export function validateUsernamePortal18(username: string = ''): string | true {
  let isValid = false;
  if (hasAtSign(username)) {
    isValid = isValidEmail(username) && username.includes('@singnet.com'); // can be 'singnet email'
  } else {
    let length = username.length;
    isValid = length >= 2 && length <= 20 && !/^\d+$/.test(username); // can be 'singnet id' (numbers length 2 to 20)
  }
  return !isValid ? ErrorMessages.INVALID_SINGNET_ID : true;
}

export function validateUsernameforGE(username: string): string | true {
  if (hasAtSign(username)) {
    return !isValidEmail(username) ? ErrorMessages.MISSING_EMAIL_USER : true; // email address
  } else if (isPlausibleDomain(username)) {
    return ErrorMessages.MISSING_EMAIL_DOMAIN;
  } else {
    return ErrorMessages.INVALID_LOGIN_ID; // none of them
  }
}

/**
 * converts mobile number for backend ( {country code}-{mobile number} )
 * @param username '+65 8877 6655'
 * @returns '65-88776655'
 */
export function formatUsernameforBackend(username: string = '', portal: string) {
  username = username.replace(' ', '-').replace(/[+ ]/g, '');
  if(portal?.toLowerCase() === 'myportal' && !hasAtSign(username)){
    username = `${username}@singnet.com.sg`;
  }
  return username;
}

export function pad(n: number) {
  return n < 10 ? '0' + n : '' + n;
}

export function toISOLocaleString(date: Date) {
  let Y = date.getFullYear();
  let M = pad(date.getMonth() + 1);
  let D = pad(date.getDate());
  let H = pad(date.getHours());
  let m = pad(date.getMinutes());
  let s = pad(date.getSeconds());

  return `${Y}-${M}-${D}T${H}:${m}:${s}`;
}

export function isIFrame() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

interface ScriptEvent {
  event: string | Event;
  script: HTMLScriptElement;
}

export function addScript(url: string) {
  return new Promise<ScriptEvent>((resolve, reject) => {
    let script = document.createElement('script');
    script.setAttribute('src', url);
    script.onload = (event) => resolve({ event, script });
    script.onerror = (event) => reject({ event, script });
    document.body.appendChild(script);
  });
}

export function isProd() {
  return window.location.hostname === 'onepass.singtel.com';
}

export const randomChars = (length = 16) => {
  let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  let output = '';
  for (let i = 0; i < length; i++) {
    output += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return output;

  // check for browser compatibility and can replace this
  // return crypto.randomUUID().replace(/\-/g, '').substr(0, length);
};

const decodeHtmlCharacters = (str: string) => {
  const htmlEntitiesRegex = /&#(\w+)(^\w|;)?/g;
  return str.replace(htmlEntitiesRegex, (match, dec) => {
    return String.fromCharCode(dec);
  });
}

export const sanitizeUrlString = (url?: string): string => {
  const invalidProtocolRegex = /^([^\w]*)(javascript|data|vbscript)/im;
  const htmlCtrlEntityRegex = /&(newline|tab);/gi;
  const ctrlCharactersRegex = /[\u0000-\u001F\u007F-\u009F\u2000-\u200D\uFEFF]/gim;
  const urlSchemeRegex = /^.+(:|&colon;)/gim;

  const sanitizedUrl = decodeHtmlCharacters(url || "")
    .replace(htmlCtrlEntityRegex, "")
    .replace(ctrlCharactersRegex, "")
    .trim();

  try {
    const target = new URL (url);
    if(target && target.protocol === 'https:' && (target.hostname.endsWith('singtel.com') || target.hostname.endsWith('atmailcloud.com'))){
      return sanitizedUrl;
    } else {
      return '';
    }
  } catch (err) {
    console.log ("Invalid URL error", err);
    return '';
  }

  if (!sanitizedUrl) {
    return '';
  }

  const urlSchemeParseResults = sanitizedUrl.match(urlSchemeRegex);
  if (!urlSchemeParseResults) {
    return sanitizedUrl;
  }

  const urlScheme = urlSchemeParseResults[0];
  if (invalidProtocolRegex.test(urlScheme)) {
    return '';
  }

  return sanitizedUrl;
}

declare global {
  interface Window {
    gtag?: any;
  }
}

export const pushAnalyticsEvent = (eventCategory: string, eventAction: string, eventLabel: string) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', eventAction, {
      event_category: eventCategory,
      event_label: eventLabel,
    });
  }
}

const vistor_agent_id = 'visitor-agent-id';

const getAgentIdFromCookie = () => {
  let fpId = getCookie(vistor_agent_id);
  return fpId;
};

const setAgentIdToCookie = (fpId) => {
  if (fpId) {
    let date = new Date();
    date.setTime(date.getTime() + 10080 * 60 * 1000); // 2days
    setCookie(vistor_agent_id, fpId, { expires: date, domain: '.singtel.com' });
  }
};

export const verifyOrSetAgentCookie = (fpId) => {
  let agentId = getAgentIdFromCookie();
  if (!agentId) {
    setAgentIdToCookie(fpId);
    agentId = fpId;
  }
  return agentId;
};


const defaultConfig = {
  "footer": [
    {
      text: 'Contact Us',
      link: 'https://www.singtel.com/contact-us'
    },
    {
      text: 'Data protection',
      link: 'https://www.singtel.com/data-protection'
    },
    {
      text: 'Terms of use',
      link: 'https://www.singtel.com/standard-agreement'
    }
  ]
};


export function getChannelLayoutConfig(flowId, channelLayoutsConfig) {
  const env = determineEnv();
  try {
    return channelLayoutsConfig[flowId][env] || defaultConfig;
  }
  catch(e) {
    return defaultConfig;
  }
}

export const determineEnv=()=> {
  try {
    const currentUrl = window.location.href;  // Get the current page URL
    const hostname = new URL(currentUrl).hostname;
    if (hostname.indexOf('onepass-pt.uat3.test.aws.singtel.com')!==-1) {
      return 'sit';
    } else if (hostname.indexOf('onepass.uat3.test.aws.singtel.com')!==-1) {
      return 'uat';
    } 
    return 'prod'
  }
  catch(e) {
    return 'prod'
  }
}