import React from "react";
import styled from "styled-components";
import { Animation } from "@dls/web";
import loading from "@dls/assets/lottiefiles/loading.json";

export const SpinnerContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  background: rgba(255, 255, 255, 0.2);
  z-index: 9;
  height: 100%;
  width: 100%;
`;

const Spinner = () => (
  <SpinnerContainer>
    <div style={{ margin: "auto" }}>
      <Animation loop={true} action="play" source={loading} color="#5C50BB" />
    </div>
  </SpinnerContainer>
);

export default Spinner;