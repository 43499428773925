import { createMediaQueries } from '@dls/web/build/helpers/mediaQueries';

export const breakpoints = {
  sm: '48em', // ~768px tablet
  md: '62em', // ~992px desktop
  lg: '75em', // ~1200px large desktop
  xl: '100em' // ~1600px
};

type Media = Record<keyof typeof breakpoints, Function>;

const media: Media = createMediaQueries(breakpoints);

export default media;
