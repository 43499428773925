import { atom } from 'recoil';

export const loginBannerErrorState = atom({
  key: 'loginBannerErrorState',
  default: ''
});

export const userConfigState = atom({
  key: 'userConfigState',
  default: {
    isPostLogin: false,
    isSingnetUser: false,
    isEmailVerify: false,
    userFlow: '',
    contact: '',
  }
});
