/* istanbul ignore file */

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getRecoil, setRecoil } from 'recoil-nexus'
import { navigate } from 'gatsby';
import { v4 as uuid } from 'uuid';
import { OtpMode } from './types';
import {
  PublicKeyResponse,
  LoginRequest,
  LoginResponse,
  VerifyChallengeRequest,
  VerifyOTPRequest,
  VerifyChallengeResponse,
  VerifyOTPResponse,
  GetChallengeResponse,
  SendOTPResponse,
  SSORequest,
  SSOResponse,
  ContactAvailabilityRequest,
  ContactAvailabilityResponse,
  AemConfigResponse
} from './RequestResponse';
import { flowConfigState } from '../index';
import { loginBannerErrorState } from '../views/LoginView/utils';

let token = '';
const openAPIs = ['/v1/idpf-auth/.well-known/keys', '/v1/idpf-auth/auth'];
const commonErrors = [
  'UNAUTHORIZED_REQUEST', 'UNAUTHORIZED',
  'TOKEN_EXPIRED', 'TOKEN_VALIDATION_FAILED',
  'INVALID_KEY', 'INVALID_SERVICE_RESULT', 'SERVICE_CALL_ERROR',
  'LDAP_USER_RETRIEVE_FAILED', 'INVALID_OTP_MODE', 'GENERATE_KEY_ERROR', '1FA_TOKEN_CREATE_FAILED',
  'TOO_EARLY_OTP_REQUEST', 'TOO_MANY_OTP_REQUESTS'];

  export const axiosInstance = axios.create({
    baseURL: process.env.GATSBY_API_ENDPOINT
  });

axiosInstance.interceptors.request.use(function onFullfilled(config: AxiosRequestConfig) {
  const flowConfig = getRecoil(flowConfigState);

  config.headers['x-client-id'] = flowConfig.clientId;
  config.headers['x-channel-id'] = flowConfig.channelId;
  
  config.headers['x-correlation-id'] = uuid();
  config.headers['x-agent-id'] = flowConfig.agentId;

  if (!openAPIs.includes(config.url) && token) {
    config.headers['token'] = token;
  }

  return config;
});

axiosInstance.interceptors.response.use(function onFullfilled(response: AxiosResponse) {
  if(response?.data?.error && commonErrors.includes(response?.data?.error?.data?.code)){
    setRecoil(loginBannerErrorState, 'Something went wrong! Please try again');
    navigate(`/login${window.location.search}`);
    return;
  }
  return response.data;
}, function (error) {
  return Promise.reject(error);
})

const Api = {
  getPublicKey: () => axiosInstance.get<void, PublicKeyResponse>('/v1/idpf-auth/.well-known/keys'),

  login: (data: LoginRequest) =>
    axiosInstance.post<void, LoginResponse>('/v1/idpf-auth/auth', data),

  _setToken: (data: string) => (token = data),

  sendOtp: (data: OtpMode) =>
    axiosInstance.post<void, SendOTPResponse>('/v1/idpf-auth/auth/otp', data),

  sendOtpForReset: (data?: OtpMode) =>
    axiosInstance.post<void, SendOTPResponse>('/v1/idpf-auth/resetpwd/otp', data),

  getChallenge: () => axiosInstance.get<void, GetChallengeResponse>('/v1/idpf-auth/auth/challenge'),

  verifyChallenge: (data: VerifyChallengeRequest) =>
    axiosInstance.post<void, VerifyChallengeResponse>('/v1/idpf-auth/auth/challenge/verify', data),

  contactAvailability: (data: ContactAvailabilityRequest) =>
    axiosInstance.post<void, ContactAvailabilityResponse>('/v1/idpf-userservice/user/contact/availability', data),

  verifyOtp: (data: VerifyOTPRequest) =>
    axiosInstance.post<void, VerifyOTPResponse>('/v1/idpf-auth/auth/otp/verify', data),

  verifyOtpForReset: (data?: VerifyOTPRequest) =>
    axiosInstance.post<void, VerifyOTPRequest>('/v1/idpf-auth/resetpwd/otp/verify', data),
  
  resetPwd: (data) =>
    axiosInstance.post('/v1/idpf-auth/resetpwd', data),
  
  verifySingpass: (data) =>
    axiosInstance.post('/v1/idpf-auth/auth/singpass/verify', data),
  
  getSSO: (data: SSORequest) =>
    axiosInstance.post<void, SSOResponse>('/v1/idpf-auth/token/exchange', data),

  getSeamlessSSO: (data: SSORequest) =>
    axiosInstance.post<void, SSOResponse>('/v1/idpf-auth/token/exchange/seamless-login', data),

  setSSO: (url, data, header) =>
    axiosInstance.post(url, data, header),

  //Registation APIs
  getKey: () => axiosInstance.get('/v1/idpf-userservice/.well-known/keys'),

  verifyNRIC: (data) =>
    axiosInstance.post('/v1/idpf-userservice/registration/verify-nric', data),
  
  verifyAccount: (data) =>
    axiosInstance.post('/v1/idpf-userservice/registration/verify-service-number', data),

  verifyEmailForAccount: (data) =>
    axiosInstance.post('/v1/idpf-userservice/registration/verify-email', data),
  
  verifyOtpForAccount: (data) =>
    axiosInstance.post('/v1/idpf-userservice/registration/verify-otp', data),

  registerAccount: (data) =>
    axiosInstance.post('/v1/idpf-userservice/registration/complete', data),

  bpverifyNRIC: (data) =>
    axiosInstance.post('/v1/idpf-userservice/bpv/verify-nric', data),

  bpverifyAccount: (data) =>
    axiosInstance.post('/v1/idpf-userservice/bpv/verify-service-number', data),

  verifyOtpForBPV: (data) =>
    axiosInstance.post('/v1/idpf-userservice/bpv/verify-otp', data),

  aemConfig: () => axios.get<AemConfigResponse>(`/sso/aem/config.json?v=${Date.now()}`)
};

export default Api;